import './ssp';
const _preparePosition = (data) => ({
    zoneId: data.zoneId,
    id: data.elm,
    width: data.w,
    height: data.h,
});
const _configAndGetAds = (position) => {
    window.sssp.config({
        ab: { ['partner_redir']: 'A' },
    });
    window.sssp.getAds(position);
    window.sssp.clearData();
};
if (window.sssp) {
    window.sklikProvider = {
        show: (data) => {
            _configAndGetAds(_preparePosition(data));
        },
    };
}
if (window.sklikData) {
    _configAndGetAds(_preparePosition(window.sklikData));
}
